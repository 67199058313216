body {
  background-color: #ffffff;
}

/* h3 {
  color: #ffffff;
} */

a {
font-family: Vollkorn, serif;
text-decoration:none;
color: #6b1f1f;

}


.avatar {
  border-radius: 10px;
  height: 22vh; 
  margin-bottom: 1vh;
  /* box-shadow: 1px 1px 1px #919191; */
}

.avatar:hover {
  animation: wiggle .6s;
  /* box-shadow: 2px 2px 1px #919191; */
  outline: none;
}

.avatar:focus {
  outline: none;
}

.avatarPapers {
  border-radius: 10px;
  height: 18vh; 
  margin-bottom: 1vh;
}

.avatarPapers:hover {
  /* box-shadow: 1px 1px 1px #919191; */
  animation: wiggle .6s;
}

.papersMobile {
  
}

.bibList {
  list-style-type: none;
  color: #ffffff;
}

.avatarRowPapers {
  margin-top: 4vh;
}

.avatarRowHome {
  margin-top: 10vh;
}

.socialLinkRow {
  margin-top: 3vh;
}

.aboutText {
  text-align: justify;
  font-size: 1.1em;
  margin-top: 1vh;
}

.bibEntry {
  text-align: justify;
  font-size: 1.1em;
  font-family: 'Vollkorn', serif;
  margin: 1.4em;
  color: #393939
}

.socialLink {
  padding: 0.5vw 0.5vw 0.5vw 0.5vw;
  font-size: 1.2em;
  color: #606060;
}

.papersMenuItem {
  font-family: 'Open Sans', sans-serif; 
  font-size: 0.8em;
  color: #3f3f3f;
}

.myName {
  margin-top: 1vh;
  font-family: 'Vollkorn', serif;
  color: #4d4d4d;
}

.myNamePapers {
  margin-top: 1vh;
  font-family: 'Vollkorn', serif;
  color: #4d4d4d;
}

.myNamePapersMobile {
  margin-top: 1vh;
  font-family: 'Vollkorn', serif;
  color: #6b1f1f;
}

.myNamePapersMobile:hover {
  animation: wiggle .6s;
  /* color: #6b1f1f */
}

.myNamePapers:hover {
  animation: wiggle .6s;
  /* color: #6b1f1f */
}

.myName:hover {
  /* text-shadow: 0.0px 0.0px 0.2px #45778d; */
  /* color: #832727; */
  animation: wiggle .6s;
}

.navBtn {
  font-family: 'Open Sans', sans-serif;
  font-weight: 100;
  margin-top: 1vh;
  color: #6b1f1f;
  /* border: 0; */
}

.navButton {
  font-family: 'Open Sans', sans-serif;
  font-weight: 100;
  margin-top: 1vh;
  color: #555555;
  /* border: 0; */
}



.aboutText {
  font-family: 'Vollkorn', serif;
  font-weight: 100;
  color: #575757;
}


@keyframes wiggle {

  0% { transform: rotate(0deg); }
  16.66% { transform: rotate(-.8deg); }
  33.32% { transform: rotate(.8deg); }
  50% { transform: rotate(0deg); }
  66.64% { transform: rotate(.8deg); }
  83.3% { transform: rotate(-.8deg); }
  100% { transform: rotate(0deg); } 
  /* 70% { transform: rotate(-.8deg); } */
  /* 80% { transform: rotate(.8deg); } */
  /* 90% { transform: rotate(0deg); }
  100% { transform: rotate(-.8deg); } */
}